<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
import checkDeviceMixin from "@/mixins/checkDeviceMixin";
export default {
  name: "App",
  mixins: [checkDeviceMixin],
  metaInfo: function () {
    return {
      title: "My page meta title",
      meta: [{ name: "description", content: "Stoferno.gr last mile courier" }],
    };
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
* {
  font-family: Roboto !important;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}
html {
  overflow: auto !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
