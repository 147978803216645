import api from "../../config";
const getJobsService = "/user/hr/jobs";
const newApplicantService = "/user/hr/new-applicant";
const state = {
  allJobs: [],
};
const getters = {};
const mutations = {
  SET_JOBS(state, jobs) {
    state.allJobs = jobs;
  },
};
const actions = {
  getJobs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .get(`${getJobsService}`)
        .then((res) => {
          commit("SET_JOBS", res.data.docs);
          resolve(res.data.voucher);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  uploadFile({ commit }, payload) {
    const { file, requestBody } = payload;
    return new Promise((resolve, reject) => {
      const url =
        file === "cv" ? "/user/hr/upload-cv" : "/user/hr/upload-cover-letter";
      api
        .post(url, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  newApplicant({ commit }, payload) {
    const { requestBody, vm } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${newApplicantService}`, { newApplicant: requestBody })
        .then((res) => {
          vm.$toast.success(res.data.message);
          resolve(res.data.voucher);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
