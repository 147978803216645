import api from "../../config";
const voucherData = "/user/couriertracking/order";
const changeDetails = "/user/couriertracking/change-details";
const confirmDetails = "/user/couriertracking/confirm-details";
const changeAddress = "/user/couriertracking/change-address";
const commentForRider = "/user/couriertracking/comment-for-rider";
const paymentChoice = "/user/couriertracking/payment-choice";
const cancelVoucher = "/user/couriertracking/cancel";
const state = {
  voucherData: null,
};
const getters = {};
const mutations = {
  VOUCHER_DATA_RESPONSE(state, payload) {
    const { vm, response } = payload;
    vm.$set(state, "voucherData", response);
  },
  CONFIRM_DETAILS(state, payload) {
    const { vm } = payload;
    vm.$set(state.voucherData.trackingActions, "approvedDetails", true);
  },
};
const actions = {
  getVoucherData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .get(`${voucherData}/${payload.id}`)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.result,
            vm: payload.vm,
          });
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  confirmDetails({ commit }, payload) {
    const { id, vm } = payload;
    return new Promise((resolve, reject) => {
      api
        .get(`${confirmDetails}/${id}`)
        .then((res) => {
          commit("CONFIRM_DETAILS", {
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  changeDetails({ commit }, payload) {
    const { vm, requestBody } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${changeDetails}`, requestBody)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.result,
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  changeAddress({ commit }, payload) {
    const { vm, requestBody } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${changeAddress}`, requestBody)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.result,
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  commentForRider({ commit }, payload) {
    const { vm, requestBody } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${commentForRider}`, requestBody)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.result,
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  paymentChoice({ commit }, payload) {
    const { vm, requestBody } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${paymentChoice}`, requestBody)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.result,
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.result);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
  cancelVoucher({ commit }, payload) {
    const { vm, requestBody } = payload;
    return new Promise((resolve, reject) => {
      api
        .post(`${cancelVoucher}`, requestBody)
        .then((res) => {
          commit("VOUCHER_DATA_RESPONSE", {
            response: res.data.docs,
            vm,
          });
          vm.$toast.success(res.data.message);
          resolve(res.data.docs);
        })
        .catch((err) => {
          if (err.response) {
            vm.$toast.error(err.response.data.message);
            reject(err.response.data.message);
          } else {
            vm.$toast.error("Κατι πήγε στραβά");
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
