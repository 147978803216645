import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f9b54a",
        secondary: "#5b69b2",
        accent: "efefef",
        orange: "#f9b54a",
        blue: "#5b69b2",
        grey: "#a2a2a2",
        error: "#f94a4a",
        red: "#f94a4a",
        success: "#79b533",
        green: "#79b533",
        lightorange: "#ffecce",
        lightblue: "#b7c0f0",
        lightgrey: "#efefef",
        black: "#000000",
      },
    },
  },
});
