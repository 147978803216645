import api from "../../config";
const searchVoucher = "/user/courierTracking/search-voucher-smallid";
const state = {
  voucherData: null,
};
const getters = {};
const mutations = {};
const actions = {
  searchVoucher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(`${searchVoucher}`, payload)
        .then((res) => {
          resolve(res.data.voucher);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject("Κατι πήγε στραβά");
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
