import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      redirect: "/",
      name: "Main",
      component: () => import("../views/Main.vue"),
      children: [
        {
          path: "home-marketplace",
          name: "HomeMarketplace",
          component: () =>
            import("../views/Home-Marketplace/HomeMarketplace.vue"),
        },
        {
          path: "courier-tracking/order/:id",
          name: "LiveTracking",
          props: true,
          component: () => import("../views/LiveTracking/LiveTracking.vue"),
        },
        {
          path: "legal",
          name: "Legal",
          redirect: "/",
          component: () => import("../views/Legal/Legal.vue"),
          children: [
            {
              path: "terms-of-use-el",
              name: "TermsOfUse",
              component: () =>
                import("../views/Legal/Legal-Components/TermsOfUse.vue"),
            },
            {
              path: "privacy-policy-el",
              name: "PrivacyPolicy",
              component: () =>
                import("../views/Legal/Legal-Components/PrivacyPolicy.vue"),
            },
            {
              path: "payments-policy-el",
              name: "PaymentsPolicy",
              component: () =>
                import("../views/Legal/Legal-Components/PaymentsPolicy.vue"),
            },
            {
              path: "cookies-policy-el",
              name: "Cookies",
              component: () =>
                import("../views/Legal/Legal-Components/Cookies.vue"),
            },
          ],
        },
        {
          path: "/",
          name: "BusinessCourier",
          component: () =>
            import("../views/Business-Courier/BusinessCourier.vue"),
        },
        {
          path: "career",
          name: "Career",
          component: () => import("../views/Career/Career.vue"),
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
