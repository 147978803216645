import "@/scss/main.scss";
import "leaflet/dist/leaflet.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as store from "./store";
import vuetify from "./plugins/vuetify";
import VueIntercom from "vue-intercom";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueSocketIO from "vue-socket.io";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;
Vue.use(VueToast, {
  position: "top",
});
Vue.use(VueIntercom, { appId: "nk7ysyy8" });
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyBz2f6LH5hS-7Hqi2lvAimaMr5TBaUoPgs",
  installComponents: false, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(
  new VueSocketIO({
    debug: process.env.NODE_ENV === "development",
    connection: "https://gback.stoferno.gr",
    options: {
      path:
        process.env.NODE_ENV === "development" ||
        process.env.VUE_APP_MODE === "dev"
          ? "/webdev/socket.io"
          : "/webs/socket.io",
    },
  })
);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-125973742-1" },
  },
  router
);

new Vue({
  router,
  store: store.default,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
