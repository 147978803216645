const state = {
  deviceSize: "large",
};
const getters = {
  isLargeDevice(state) {
    return state.deviceSize === "large";
  },
};
const mutations = {
  UPDATE_DEVICE_SIZE(state, payload) {
    const { vm, screen } = payload;
    vm.$set(state, "deviceSize", screen);
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
