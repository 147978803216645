import { mapMutations } from "vuex";
export default {
  data: () => {
    return {
      screen: "large",
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapMutations({
      updateDevice: "device/UPDATE_DEVICE_SIZE",
    }),
    onResize() {
      if (matchMedia("(max-width: 1263px)").matches) {
        if (this.screen === "large") {
          this.updateDevice({ screen: "small", vm: this });
        }
        this.screen = "small";
      } else {
        if (this.screen === "small") {
          this.updateDevice({ screen: "large", vm: this });
        }
        this.screen = "large";
      }
    },
  },
};
