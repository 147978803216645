import axios from "axios";
window.CancelToken = axios.CancelToken.source();
window.pendingRequests = 0;

let baseUrl = "";
if (
  process.env.NODE_ENV === "development" ||
  process.env.VUE_APP_MODE === "dev"
) {
  baseUrl = "https://gback.stoferno.gr/dev/";
} else {
  baseUrl = "https://gback.stoferno.gr/";
}

const api = axios.create({
  baseURL: baseUrl,
});

export default api;
