import api from "../config";

const state = {};
const getters = {};
const mutations = {};
const actions = {
  feedback({ commit }, payload) {
    const { vm, feedContent } = payload;
    return new Promise((resolve, reject) => {
      api
        .post("/user/feedback/postfeedback", { feedContent })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
